<script setup lang="ts">
    console.log('onboarding layout loaded');

    /** data() {
    return {
      scroll: 0,
      scrollDir: "up",
      showSystem: true,
      specialTheme: "",
    };
  }, */

    const scroll = ref(0);
    const scrollDir = ref('up');
    const showSystem = ref(true);
    const specialTheme = ref('');

    onMounted(() => {
        console.log('onboarding layout mounted');
        // only allow trusted shops on client
        // if (process.client && this.trustedShopId) {
        //   trustedShopsPlugin(this.trustedShopId)
        // }
        // if (document && this.specialTheme !== '') {
        //   const body = document.querySelector('body')
        //   if (body) {
        //     body.setAttribute('woom-theme', this.specialTheme)
        //   }
        // }

        if (document && specialTheme.value !== '') {
            const body = document.querySelector('body');
            if (body) {
                body.setAttribute('woom-theme', specialTheme.value);
            }
        }
    });

    //     beforeMount() {
    //     window.addEventListener('scroll', this.handleScroll, {
    //       capture: true,
    //       passive: true,
    //     })
    //     this.handleScroll()
    //   },
    //   beforeDestroy() {
    //     if (window) {
    //       window.removeEventListener('scroll', this.handleScroll, {
    //         capture: true,
    //         passive: true,
    //       })
    //     }
    //   },
</script>

<template>
    <div class="woom-app woom-theme">
        <client-only>
            <lazy-language-drawer></lazy-language-drawer>
        </client-only>
        <mol-header></mol-header>
        <nuxt-page />
        <mol-footer></mol-footer>
    </div>
</template>

<style>
    .widget {
        max-width: 100vw !important;
    }
    .widget:not(#dr_cookie_banner_container[class*='cc-invisible']) + #__nuxt {
        opacity: 0.2;
    }
    .woom-app {
        max-width: 100vw;
    }

    #trustedShopBadgeWoom {
        position: fixed;
        top: calc(100vh - 78px - 16px);
        left: 16px;
        z-index: 2;
        transition: opacity 0.4s ease-out;
        opacity: 0;
    }

    #trustedShopBadgeWoom .showTrustedShopBadgeWoom {
        opacity: 1;
    }

    /* this is a hack because trusted shop is ignoring all configurations if there is no single review */
    #trustedShopBadgeWoom .showTrustedShopBadgeWoom ._15ma014 {
        left: 16px !important;
    }
    .pink-bike {
        --color-primary: #f372a6;
        --color-red: #f372a6;
        --logo-color: #f372a6;
    }

    .pink-bike .woom-notifications {
        background-color: #f372a6 !important;
        color: white !important;
        --color: white;
        --text-color-light-grey: white;
    }

    /* TODO: update from old site */
    .pink-bike .woom-notifications .woom-notification,
    p {
        /* color: white !important; */
    }
    body[woom-theme='pink-bike'] .cc-banner .cc-allow,
    body[woom-theme='pink-bike'] .cc-banner .cc-allow:focus {
        background-color: #f372a6;
    }

    body[woom-theme='pink-bike'] .cc-banner .cc-allow:hover {
        color: white !important;
    }

    body[woom-theme='pink-bike'] .cc-banner .dr-headline {
        color: #f372a6 !important;
    }
    body[woom-theme='pink-bike'] .cc-banner li.dr-category-headline {
        color: #f372a6 !important;
    }
    body[woom-theme='pink-bike'] .cc-banner .dr-tab-category-morelink {
        color: #f372a6 !important;
    }
    body[woom-theme='pink-bike'] .cc-banner #dr-hiddencat-btn-apply {
        background-color: #f372a6 !important;
        color: white !important;
    }
    body[woom-theme='pink-bike'] .cc-banner #dr-hiddencat-btn-apply:hover {
        color: white !important;
    }
    body[woom-theme='pink-bike'] .cc-revoke.cc-bottom {
        background-color: #f372a6 !important;
        color: white !important;
    }
    body[woom-theme='pink-bike'] .cc-revoke.cc-bottom:hover {
        color: white !important;
    }

    @media only screen and (max-width: 728px) {
        .woom-app {
            overflow-x: hidden;
        }
    }
</style>
